import * as Yup from 'yup';

export const CreateUserSchema = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .email('Ingrese un correo valido')
    .required('Este campo es requerido'),
  password: Yup.string()
    .min(8, 'La contrasña debe tener al menos 8 caracteres')
    .required('Este campo es requerido'),
  passwordCheck: Yup.string()
    .min(8, 'La contrasña debe tener al menos 8 caracteres')
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Este campo es requerido')
})

export const EditUserSchema = Yup.object().shape({
  name: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .email('Ingrese un correo valido')
    .required('Este campo es requerido'),
  password: Yup.string()
    .min(8, 'La contrasña debe tener al menos 8 caracteres'),
  passwordCheck: Yup.string()
    .min(8, 'La contrasña debe tener al menos 8 caracteres')
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
})