import styled from 'styled-components';
import { CardPanel } from 'react-materialize';
import { 
  Button as ThemeBotton, 
  Input as ThemeInput, 
} from '../../styles/Theme';

export const LoginWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 450px);
  grid-template-rows: 200px auto;
  justify-content: center;
  align-content: center;
`;

export const Form = styled.form``;

export const LogoContainer = styled.div`
  align-self: flex-end;
  justify-self: center;
  max-width: 120px;
  & img{
    width: 100%;
  }
`;


export const FormPanel = styled(CardPanel)`
  /* display: grid;
  grid-template-rows: 70px 70px 40px; */
`;

export const Input = styled(ThemeInput)`
  margin-top: 0px;
`;

export const Button = styled(ThemeBotton)`
  justify-self: center;
  align-self: center;
`;

export const ErrorContainer = styled.p`
  color: #dc3545;
  padding: 10px;
  text-align: center;
`