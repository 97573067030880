import { baseUrl } from "../config";
import { api } from "../utils/api";

// Function: ejecuta el fetch para traer la lista de usuarios GET: /site/modules
export const getModules = async (token) => {  
  const endpoint = `${baseUrl}/site/modules`;
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer la lista de usuarios GET: /users
export const getUsers = async (token) => {  
  const endpoint = `${baseUrl}/users`;
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer 1 usuario GET: /users/:id
export const getUser = async (id, token) => {  
  const endpoint = `${baseUrl}/users/${id}`;
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para agregar 1 usuario POST: /users/
export const addUser = async (body, token) => {  
  const endpoint = `${baseUrl}/users`;
  const response = await api(endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar 1 usuario PUT: /users/:id
export const updateUser = async (id, body, token) => {  
  const endpoint = `${baseUrl}/users/${id}`;
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar 1 usuario PUT: /users/assign/:id
export const assignStores = async (id, body, token) => {  
  const endpoint = `${baseUrl}/users/assign/${id}`;
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar 1 usuario PUT: /users/:id
export const disableUser = async (id, token) => {  
  const endpoint = `${baseUrl}/users/${id}`;
  const response = await api(endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para pedir el perfil del usuario POST: /user/profile
export const profile = async (token) => {    
  const endpoint = `${baseUrl}/users/profile`;
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};
