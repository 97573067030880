import styled from 'styled-components';
import {
  Table as MaterialTable,
  Icon as MaterialIcon,
} from 'react-materialize';

export const Table = styled(MaterialTable)`
  width: 100%;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: inline;
`;

export const Icon = styled(MaterialIcon)``;

