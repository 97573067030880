import React from 'react';
import { Formik } from 'formik';

import { SideTitle, Input, Button, ValidationMessage, Select, Icon } from '../../../styles/Theme';
import { PermissionsWrapper, ButtonWrapper } from './styles';
import { PermissionCheckboxs } from './PermissionCheckboxs';

import { CreateUserSchema, EditUserSchema } from './schema';
import { getType, getModules, getUserPermissions } from '../../../utils/access';

export const UserForm = ({ data, onAdd, onUpdate, formToEdit, modules, authType, onClose, onBack }) => {
  
  const type = getType(data);
  const userModules = getModules(data);
  const userPermissions = getUserPermissions(data, userModules);

  const initialValues = {
    email: data.email || '',
    name: data.name || '',
    type: type || 3,
    password: '',
    passwordCheck: '',
    modules: userModules || [], 
    permissions: userPermissions || {}
  }

  const handlerSubmit = (values) => {
    formToEdit ? onUpdate(data._id, values) : onAdd(values); 
  } 

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formToEdit ? EditUserSchema : CreateUserSchema}
      onSubmit={(values, { setSubmitting }) => handlerSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {

        return (
          <>
            <SideTitle>{formToEdit ? 'Modificar' : 'Agregar'} Usuario </SideTitle>

            <form onSubmit={handleSubmit}>
              <Input
                label="Email"
                id="email"
                type="text"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={formToEdit}
              />
              {errors.email && touched.email && <ValidationMessage>{errors.email}</ValidationMessage>}
              <Input
                label="Name"
                id="name"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && <ValidationMessage>{errors.name}</ValidationMessage>}
              <Select
                label="Tipo de usuario"
                id="type"
                type="select"
                name="type"
                onChange={handleChange}
                onBlur={handleBlur}
                value={type}
              >
                <option value={3}> Cliente </option>
                <option value={2}> Soporte </option>
                <option value={1} disabled={authType !== 1}>Administrador</option>
              </Select>
              {errors.type && touched.type && <ValidationMessage>{errors.type}</ValidationMessage>}
              <Input
                label="Contraseña"
                id="password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {errors.password && touched.password && <ValidationMessage>{errors.password}</ValidationMessage>}
              <Input
                label="Repetir Contraseña"
                id="password-check"
                type="password"
                name="passwordCheck"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordCheck}
              />
              {errors.passwordCheck && touched.passwordCheck && <ValidationMessage>{errors.passwordCheck}</ValidationMessage>}
              <Select
                label="Modulos"
                id="modules"
                type="select"
                name="modules"
                onChange={handleChange}
                onBlur={handleBlur}
                value={userModules}
                multiple={true}
              >
                {modules.map(module => <option key={module.order} value={module.key} >{module.name}</option>)}
              </Select>

              {values.modules && values.modules.map((module, key) => (
                <PermissionsWrapper key={key}>
                  <PermissionCheckboxs
                    data={data}
                    module={module}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </PermissionsWrapper>
              ))}
              {!values.modules && userModules && userModules.map((module, key) => (
                <PermissionsWrapper key={key}>
                   <PermissionCheckboxs
                    data={data}
                    module={module}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </PermissionsWrapper>
              ))}
              <ButtonWrapper>
                {Boolean(formToEdit) && <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>}
                {!Boolean(formToEdit) && <Button onClick={onClose} type="button"><Icon> close </Icon></Button>}
                <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
              </ButtonWrapper>
            </form>
          </>

        )
      }}

    </Formik>
  )
}