import React from 'react';
import { Button, Modal as StyledModal } from './styles';

export const Modal = ({ show, header, message, actions }) => {
  
  return (
    <StyledModal
      actions={[
        actions.map((action) => <Button flat onClick={action.function}  waves="green">{action.description}</Button>)
      ]}
      bottomSheet={false}
      fixedFooter={false}
      header={header}
      id="modal"
      open={show}
      options={{
        dismissible: true,
        endingTop: '10%',
        inDuration: 250,
        onCloseEnd: null,
        onCloseStart: null,
        onOpenEnd: null,
        onOpenStart: null,
        opacity: 0.5,
        outDuration: 250,
        preventScrolling: true,
        startingTop: '4%'
      }}
    >
      <p>{message}</p>
    </StyledModal>
  )
}