import React from 'react';
import { InfoWrapper, Item, ItemLabel, ItemValue, ButtonsWrapper } from './styles';
import { SideTitle, Button, Icon } from '../../../styles/Theme';
import { stringify } from '../../../utils/objects';

export const ProductInfo = ({ data, onClose }) => {
  const { publishStatus = {} } = data;
  return (
    <InfoWrapper>
      <SideTitle>Datos del producto</SideTitle>
      <Item>
        <ItemLabel>CODIGO: </ItemLabel> <ItemValue> {data.idmenu} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>NOMBRE: </ItemLabel> <ItemValue> {data.nombre} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>DESCRIPCION: </ItemLabel> <ItemValue> {data.descripcion} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>CATEGORIA: </ItemLabel> <ItemValue> {data.nombre_categoriamenu} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>CANTIDAD: </ItemLabel> <ItemValue> {data.checkStock} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>ESTADO: </ItemLabel> <ItemValue> {publishStatus.status ? 'Publicado' : 'Sin publicar'} </ItemValue>
      </Item>
      {publishStatus.error &&
        <Item>
          <ItemLabel>ERROR: </ItemLabel> <ItemValue> {stringify(publishStatus.error)} </ItemValue>
        </Item>
      }
      <ButtonsWrapper>
        {/* <Button onClick={() => onEdit(data._id)}><Icon> edit </Icon></Button> */}
        {/* <Button onClick={() => onDisable(data._id)}><Icon> not_interested </Icon></Button> */}
        <Button onClick={() => onClose()}><Icon> close </Icon></Button>
      </ButtonsWrapper>
    </InfoWrapper>
  )
}