import styled from 'styled-components';
import { 
  SideTitle as ThemeSideTitle,
  Button as ThemeButton,
  Icon as ThemeIcon,
  ValidationMessage as ThemeValidationMessage,
  Select as ThemeSelect
} from '../../../styles/Theme';

export const ButtonWrapper = styled.div``;

export const Button = styled(ThemeButton)``;

export const Icon = styled(ThemeIcon)``;

export const ValidationMessage = styled(ThemeValidationMessage)``;

export const Select = styled(ThemeSelect)``;

export const SideTitle = styled(ThemeSideTitle)``;

