import React from 'react';

import { FilterWrapper, ButtonsWrapper, Select, Checkbox } from './styles';

export const Filters = ({ onSelectStore, stores, showMoreFilters, handlerFilter }) => {
  return (
    <FilterWrapper>
      <h5>Filtros</h5>
      <Select
        onChange={(e) => onSelectStore(e)}
        value=""
      >
        <option disabled value="" > Seleccionar tienda </option>
        {stores.map(store => <option value={store._id} key={store._id}> {store.name} </option>)}
      </Select>
      {showMoreFilters &&
        <ButtonsWrapper>
          <Checkbox
            label="Publicados"
            id={`filter-success`}
            name={`success`}
            onChange={handlerFilter}
            checked={false}
            value={'1'}
          />
          <Checkbox
            label="Erroneos"
            id={`filter-errors`}
            name={`errors`}
            onChange={handlerFilter}
            checked={false}
            value={'1'}
          />
        </ButtonsWrapper>
      }
    </FilterWrapper>
  )
}