import { Word } from './translate';

export const buildAccess = (data) => {
  try {
    const {
      type,
      modules,
      permissions,
      status = true
    } = data;
    const numberType = Number(type);
    let newPermissions = {}
    let newModules = []
    for (const module of modules) {
      if (numberType === 3 && module !== "users") {
        newPermissions[module] = {
          create: permissions[module].includes('create'),
          review: permissions[module].includes('review'),
          update: permissions[module].includes('update'),
          disable: permissions[module].includes('disable'),
        }

        newModules.push({
          order: newModules.length,
          name: Word[module],
          key: module
        })
      }
      if (numberType !== 3) {
        newPermissions[module] = {
          create: permissions[module].includes('create'),
          review: permissions[module].includes('review'),
          update: permissions[module].includes('update'),
          disable: permissions[module].includes('disable'),
        }

        newModules.push({
          order: newModules.length,
          name: Word[module],
          key: module
        })
      }
    }
    const access = {
      type: numberType,
      status,
      modules: newModules,
      permissions: newPermissions
    };
    
    if (access) return access;
    return {};
  } catch (error) {
    return {};
  }
}

export const getAccess = (user, module, permission) => {
  try {
    const { access: { permissions: { [module]: { [permission]: access } = {} } = {} } = {} } = user;
    if (access) return true;
    return false;
  } catch (error) {
    return false;
  }
}

export const getType = (user) => {
  if (!user) return 3;
  const { access: { type = 3 } = 3 } = user;
  return `${type}`;
}

export const getModules = (user) => {
  if (!user) return [];
  const { access: { modules = [] } = [] } = user;
  if (modules.length) return modules.map(module => module.key)

  return modules;
}

export const getPermissionsByModule = (user, module) => {
  if (!user || !module) return {}
  const { access: { permissions: { [module]: permission = {} } = {} } = {} } = user;

  return permission;
}

export const getUserPermissions = (user, modules) => {
  if (!user || !modules) return {}
  const { access: { permissions } = {} } = user;
  if (!permissions) return {}
  const userPermissions = {}
  for (const module of modules) {
    const keys = Object.keys(permissions[module])
    userPermissions[module] = keys.filter((key => permissions[module][key]));
  }

  return userPermissions;
}