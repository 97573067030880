import React from 'react';
import { IconWrapper } from './styles';
import { Table, Row, Cell, Icon } from '../../../styles/Theme';
export const ProductsList = ({ products, onReview }) => {
  const headers = ['Id', 'Nombre', 'Cantidad', 'Categoria', 'Publicado'];

  return (
    <>
      {products && products.length > 0 &&
        <>
          <Table centered>
            <thead>
              <Row>
                {headers.map((title, index) => <th key={index}>{title}</th>)}
                <th>Opciones</th>
              </Row>
            </thead>
            <tbody>
              {products.map(product => {
                const { publishStatus = {} } = product;
                return (
                  <Row key={product._id} disable={!publishStatus?.status}>
                    <Cell>{product.idmenu}</Cell>
                    <Cell>{product.nombre}</Cell>
                    <Cell>{product.checkStock}</Cell>
                    <Cell>{product.nombre_categoriamenu}</Cell>
                    <Cell>{publishStatus?.status ? 'Si' : 'No'}</Cell>
                    <Cell>
                      <IconWrapper onClick={() => onReview(product._id)} ><Icon> more_horiz </Icon></IconWrapper>
                    </Cell>
                  </Row>
                )
              }
              )
              }
            </tbody>
          </Table>
        </>
      }
    </>
  )
}