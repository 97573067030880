import React, { createContext, useState } from 'react';
import jwt from 'jwt-decode';
import { profile } from './apis/users';
import { isEmpty } from './utils/objects';

export const Context = createContext();

const Provider = ({ children }) => {

  // Global: token utilizaro en las peticiones fetch.
  const [token, setToken] = useState(() => {
    return window.sessionStorage.getItem("token");
  });

  // Global: modules correspondientes al usuario autenticado.
  const [modules, setModules] = useState([]);

  // Global: permisos correspondientes al usuario autenticado.
  const [permissions, setPermissions] = useState({});

  // Global: usuario autenticado
  const [name, setName] = useState(() => {
    return window.sessionStorage.getItem("name");
  });
  // Global: usuario autenticado
  const [email, setEmail] = useState(() => {
    return window.sessionStorage.getItem("email");
  });

  const [stores, setStores] = useState([]);

  // Global: usuario autenticado
  const [authType, setAuthType] = useState(0);

  const value = {
    token,
    name,
    email,
    authType,
    modules,
    permissions,
    stores,
    storeId: '5edca560fcab4b38a86d2a65',
    setStores,
    
    // Function: recibe el token y lo setea en la variable global
    activateAuth: token => {
      const payload = jwt(token);

      window.sessionStorage.setItem("name", payload.name);
      window.sessionStorage.setItem("email", payload.email);
      window.sessionStorage.setItem("token", token);

      setName(payload.name);
      setEmail(payload.email);
      setToken(token);
    },

    // Function: limpia todas variables, localStorage y sessionStorage
    removeAuth: () => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setStores([]);
      setToken(undefined);
      setName(undefined);
      setEmail(undefined);
      
    },

    handlerProfile: async (token) => {
      const profileResponse = await profile(token);
      const { response: { access } = {}} = profileResponse;
      if(isEmpty(access)) {
        setAuthType(0);
        setModules([]);
        setPermissions({});
        setStores([]);
        setToken(undefined);
        setName(undefined);
        setEmail(undefined);
        window.localStorage.clear();
        window.sessionStorage.clear();
      } else {
        setAuthType(Number(access.type));
        setModules(access.modules);
        setPermissions(access.permissions);
      }
    }
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;

}

export default {
  Provider,
  Consumer: Context.Consumer
};