import styled, { css } from 'styled-components';
import {
  Collapsible as MaterialCollapsible,
  CollapsibleItem as MaterialCollapsibleItem,
  Collection as MaterialCollection,
  CollectionItem as MaterialCollectionItem,
  Icon as MaterialIcon
} from 'react-materialize';


export const Collapsible = styled(MaterialCollapsible)`
 
`;


export const CollapsibleItem = styled(MaterialCollapsibleItem)`
  &&& {
    ${props =>
      props.able === 'false' && css`
        color: #b9a5a5;
      `
    }
  }
`;

export const Collection = styled(MaterialCollection)``;

export const CollectionItem = styled(MaterialCollectionItem)`
  display: flex;
  &&& {
    ${props =>
      props.able === 'false' && css`
        color: #b9a5a5;
      `
    }
  }
`;

export const Icon = styled(MaterialIcon)`
  
`;

export const CollapsibleHeaderText = styled.div`
  flex-grow: 2;
`

export const ActionsWrapper = styled.div`
  flex-grow: 0;
`;

export const IconWrapper = styled.div`
  &&&{
    cursor: pointer;
    display: inline;
  }
`;

export const TextWrapper = styled.div`
  flex-grow: 2;
`;

export const LabelText = styled.label`
  font-weight: bold;
  text-transform: uppercase;
`;

export const SpanText = styled.span`
  font-weight: bold;
`;