import React from 'react';
import { IconWrapper } from './styles';
import { Table, Icon, Row, Cell } from '../../../styles/Theme';
import { Word } from '../../../utils/translate';
import { getType } from '../../../utils/access';
export const UsersList = ({ users, onReview }) => {
  const headers = ['Nombre', 'Correo', 'Tipo', 'Opciones'];
  
  return (
    <>
      {users && users.length > 0 &&
        <>
          <Table centered>
            <thead>
              <tr>
                {headers.map((title, index) => <th key={index}>{title}</th>)}
              </tr>
            </thead>
            <tbody>
              {users.map(user => {
                return (
                  <Row key={user._id } disable={user.access? !user.access.status : 1}>
                    <Cell>{user.name}</Cell>
                    <Cell>{user.email}</Cell>
                    <Cell>{Word[`user-${getType(user)}`]}</Cell>
                    <Cell>
                      <IconWrapper onClick={() => onReview(user._id)} ><Icon> more_horiz </Icon></IconWrapper>
                    </Cell>
                  </Row>
                )
              }
              )
              }
            </tbody>
          </Table>
        </>
      }
    </>
  )
}