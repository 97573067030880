export const isEmpty = (data) => {
  if (typeof (data) === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true;
    } else if (!data) {
      return true;
    }
    return false;
  } else if (typeof (data) === 'string') {
    if (!data.trim()) {
      return true;
    }
    return false;
  } else if (typeof (data) === 'undefined') {
    return true;
  } else {
    return false;
  }
}

export const stringify = (object) => {
  let string = '';
  try {
    string = JSON.stringify(object);
    return string;
  } catch (error) {
    return string = 'Error convirtiendo de objeto a texto';
  }
}