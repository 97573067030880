import { baseUrl } from "../config";
import { api } from "../utils/api";


// Function: ejecuta el fetch para traer la lista de tiendas GET: /stores
export const getStores = async (token, page = 1) => {  
  
  const endpoint = `${baseUrl}/stores?page=${page}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};


// Function: ejecuta el fetch para traer la lista de tiendas GET: /stores
export const getMainStores = async (token) => {  
  
  const endpoint = `${baseUrl}/stores/main`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer la lista de tiendas GET: /stores
export const getClientStores = async (token, page = 1) => {  
  
  const endpoint = `${baseUrl}/stores/client?page=${page}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer la lista de tiendas GET: /stores/availables/:id
export const getAvailablesStores = async (id, token) => {  
  
  const endpoint = `${baseUrl}/stores/availables/${id}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer una tienda GET: /stores/:id
export const getStore = async (id, token) => {  
  
  const endpoint = `${baseUrl}/stores/${id}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para agregar una tienda POST: /stores/
export const addStore = async (body, token) => {  
  
  const endpoint = `${baseUrl}/stores`;
  
  const response = await api(endpoint, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};


// Function: ejecuta el fetch para modificar la configuracion de una tienda PUT: /stores/config/:id
export const updateConfigStore = async (id, body, token) => {  
  
  const endpoint = `${baseUrl}/stores/config/${id}`;
  
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar la configuracion de una tienda PUT: /stores/config/payment/:id
export const updatePaymentInfo = async (id, body, token) => {  
  
  const endpoint = `${baseUrl}/stores/config/payment/${id}`;
  
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar una tienda PUT: /stores/:id
export const updateStore = async (id, body, token) => {  
  
  const endpoint = `${baseUrl}/stores/${id}`;
  
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar una tienda PUT: /stores/:id
export const disableStore = async (id, token) => {  
  
  const endpoint = `${baseUrl}/stores/${id}`;
  
  const response = await api(endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para modificar una tienda PUT: /stores/:id
export const restartStore = async (id, token) => {  
  
  const endpoint = `${baseUrl}/stores/restart/${id}`;
  
  const response = await api(endpoint, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

