export const getErrorMessage = (status = undefined) => {
  let message = undefined;
  switch (status) {
    case 500:
      message = "Error 500 en el servidor"
      break;
    case 401:
      message = "Credenciales inválidas"
      break;
    case 400:
      message = "Parámetros inválidos"
      break;
    case 403:
      message = "No autorizado"
      break;
  
    default:
      message = "Error de comunicación con el servidor";
  }
  return message;
}