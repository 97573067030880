import React, { useState, useEffect, useContext } from 'react';
import { getAvailablesProducts, getProduct } from '../apis/products';
import { getClientStores } from '../apis/stores';
import { ProductsList } from '../components/product/ProductsList';
import { ProductInfo } from '../components/product/ProductInfo';
import { Modal } from "../components/Modal";
import { Pagination } from '../components/Pagination';
import { Filters } from '../components/product/Filters';
import { Title } from '../components/Title';
import { isEmpty } from '../utils/objects';
import { SidePanel as ProductPanel, Overlay, ProgressBar } from '../styles/Theme';
import { Context } from '../Context';

export const ProductsContainer = () => {

  const { token, stores, setStores } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState('');
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [store, setStore] = useState(false);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [filter, setFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [actionsModal, setModalActions] = useState([]);

  const handlerEdit = () => {
    setLoading(true);
    setShow(true);
    setLoading(false);
  }

  const handlerClose = () => {
    setData({});
    setShow(false);
  }

  const handlerCloseModal = () => {
    setModalMessage("");
    setModalHeader("Modal");
    setModalActions([]);
    setShowModal(false);
  }

  const handlerFilters = async (e) => {
    const key = e.target.name;
    const value = e.target.checked;
    const newFilter = { ...filter, [key]: value }
    setFilter(newFilter);
  }

  const handlerStoreFilter = (e) => {
    if (e.target.value) {
      setStore(e.target.value);
      setShowMoreFilters(true);
    } else {
      setStore(undefined);
      setShowMoreFilters(false);
    }
  }

  const handlerShowPanel = async (_id) => {
    setLoading(true);
    const { error = undefined, response = {} } = await getProduct(_id, token);
    if (!isEmpty(response)) {
      setData(response);
      setShow('info');
    }
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener el datalle del producto");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return false;
    }
  }


  const handlerPagination = (value) => {
    setPage(value)
  }

  const productInfo = (
    <ProductInfo
      data={data}
      onEdit={(id) => handlerEdit(id)}
      onClose={() => handlerClose()}
    />
  )

  const handlerShowCase = (showCase) => {
    let component = undefined;
    switch (showCase) {
      case 'info':
        component = productInfo;
        break;
      case 'form':
        component = <></>
        break;

      default:
        component = <></>
        break;
    }

    return component
  }

  const getProducts = async () => {
    setLoading(true);
    const { error = undefined, response = {} } = await getAvailablesProducts(store, token, page, filter);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener la lista de productos");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);

      return [];
    }
    return response;
  }

  const hanlerGetClientStores = async () => {
    setLoading(true);
    const { error = undefined, response = [] } = await getClientStores(token);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener la lista de tiendas");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return [];
    }
    return response;
  }

  useEffect(() => {
    const asyncRequest = async () => {
      const { stores: storeList = [] } = await hanlerGetClientStores();
      setStores(storeList);
    }

    asyncRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const asyncRequest = async () => {
      const { products = [], pagination = {} } = await getProducts();
      setProducts(products);
      setPagination(pagination);
    }
    if (store) {
      asyncRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, page, filter]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showModal]);

  return (
    <>
      {loading && <ProgressBar />}

      {showModal && <Modal show={showModal} header={headerModal} message={modalMessage} actions={actionsModal} />}

      {show &&
        <>
          <Overlay onClick={handlerClose} show={show} />
          <ProductPanel show={show} >
            {handlerShowCase(show)}
          </ProductPanel>
        </>
      }

      <Title title="Productos" />
      {stores && stores.length > 0 &&
        <Filters
          onSelectStore={(e) => handlerStoreFilter(e)}
          stores={stores}
          showMoreFilters={showMoreFilters}
          handlerFilter={(value) => handlerFilters(value)}
        />
      }
      {products && products.length > 0 &&
        <>
          <ProductsList
            products={products}
            onReview={(id) => handlerShowPanel(id)}
          />
          {pagination.totalPages > 1 &&
            <Pagination
              pagination={pagination}
              onSetPage={(value) => handlerPagination(value)}
            />
          }
        </>
      }
    </>
  )
}