import React from 'react';
import { Formik } from 'formik';

import { SideTitle, Button, Icon, Switch } from '../../../styles/Theme';
import { ButtonWrapper, OptionWrapper } from './styles';



export const ConfigManual = ({ data, onSave, onBack }) => {

  const { config: { manual = {} } = {} } = data;

  const initialValues = {
    title: Boolean(manual.title),
    description: Boolean(manual.description),
    image: Boolean(manual.image),
    type: Boolean(manual.type),
    tags: Boolean(manual.tags),
    price: Boolean(manual.price),
    amount: Boolean(manual.amount),
    variants: Boolean(manual.variants)
  }
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => onSave(data._id, values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {

        return (
          <>
            <SideTitle>Configurar actualizacion manual </SideTitle>

            <form onSubmit={handleSubmit}>
              <OptionWrapper>
                <Switch
                  checked={values.title}
                  id="title"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Nombre"
                  value={true}
                  name="title"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.description}
                  id="description"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Descripción"
                  value={true}
                  name="description"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.image}
                  id="image"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Imagen"
                  value={true}
                  name="image"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.type}
                  id="type"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Tipo de producto"
                  value={true}
                  name="type"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.tags}
                  id="tags"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Etiquetas"
                  value={true}
                  name="tags"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.price}
                  id="price"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Precio"
                  value={true}
                  name="price"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.amount}
                  id="amount"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Cantidad"
                  value={true}
                  name="amount"
                  onBlur={handleBlur}
                />
              </OptionWrapper>

              <OptionWrapper>
                <Switch
                  checked={values.variants}
                  id="variants"
                  offLabel=""
                  onChange={handleChange}
                  onLabel="Modificadores"
                  value={true}
                  name="variants"
                  onBlur={handleBlur}
                />
              </OptionWrapper>


              <ButtonWrapper>
                <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>
                <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
              </ButtonWrapper>
            </form>
          </>

        )
      }}

    </Formik>
  )
}