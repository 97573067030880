import styled from 'styled-components';

import { 
  SideTitle as ThemeSideTitle,
  Input as ThemeInput,
  Button as ThemeButton,
  ValidationMessage as ThemeValidationMessage,
  Select as ThemeSelect,
  Icon as ThemeIcon,
  Checkbox as ThemeCheckbox
} from '../../../styles/Theme';

export const SideTitle = styled(ThemeSideTitle)``;
export const Input = styled(ThemeInput)``;
export const Button = styled(ThemeButton)``;
export const ValidationMessage = styled(ThemeValidationMessage)``;
export const Select = styled(ThemeSelect)``;
export const Icon = styled(ThemeIcon)``;
export const Checkbox = styled(ThemeCheckbox)``;

export const Divider = styled.span`
  display: block; 
  border-bottom: 2px #f0f0f0 solid;
  font-weight: bold;
  font-size: 16px;
  margin: 20px 0px 5px 0px;
  color: #9e9e9e;
`

export const PermissionsWrapper = styled.div`
  padding: 10px 2px 0px 2px;
  margin-bottom: 5px;
`;

export const PermissionTitle = styled.span`
  font-size: 16px;
  margin-right: 5px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
`;