import styled, {css} from 'styled-components';
import { Link } from '@reach/router';
import {
  SideNav,
  Button,
  Icon as MaterializeIcon
} from 'react-materialize';
import { tertiaryColor } from '../../config'

export const SidebarWrapper = styled(SideNav)`
  width: 220px;
`
export const HeaderLogoWrapper = styled.div`
  ${props => props.authType === 1 && css`
    background: #175b70;
  `}

  ${props => props.authType === 2 && css`
    background: #397d61;
  `}

  ${props => props.authType === 3 && css`
    background: #063203;
  `}
`; 

export const SideButton = styled(Button)`
  color: rgba(0,0,0,0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  width: 100%;
  background: transparent;
  box-shadow: none;

  &:hover {
    background-color: ${tertiaryColor};
    box-shadow: none;
  }
  &:active {
    background-color: ${tertiaryColor};
    box-shadow: none;
  }
  &:focus {
    background-color: ${tertiaryColor};
    box-shadow: none;
  }
`;

export const SideLink = styled(Link)`
`;

export const DataUserWrapper = styled.div`
  position: relative;
`;

export const HeaderLogo = styled.img`
  width: 100%;
`;

export const Icon = styled(MaterializeIcon)``
