import * as Yup from "yup";

const requiredMessage = "Este campo es requerido";

export const createStoreSchema = Yup.object().shape({

  type: Yup.string().required(requiredMessage),

  name: Yup
    .string()
    .required(requiredMessage),

  invuToken: Yup.string().required(requiredMessage),

  shopifyPassword: Yup
    .mixed()
    .when("type", {
      is: "1",
      then: Yup
        .string()
        .required(requiredMessage)
    }),

  wooCommerceUrl: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    }),

  wooCommerceKey: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    }),

  wooCommerceSecret: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    })
})

export const editStoreSchema = Yup.object().shape({
  type: Yup.string().required(requiredMessage),

  name: Yup.string().required(requiredMessage),

  
  invuToken: Yup.string().required(requiredMessage),

  shopifyPassword: Yup
  .string()
  .when("type", {
    is: "1",
    then: Yup
      .string()
      .required(requiredMessage)
  }),

  pedidosYaId: Yup
  .string()
  .when("type", {
    is: "2",
    then: Yup
      .string()
      .required(requiredMessage)
  }),

  wooCommerceUrl: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    }),

  wooCommerceKey: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    }),

  wooCommerceSecret: Yup
    .string()
    .when("type", {
      is: "3",
      then: Yup
        .string()
        .required(requiredMessage)
    })
})