import React, { useContext } from 'react';
import { Sidebar } from '../components/Sidebar';
import { Context } from '../Context';

export const SidebarContainer = () => {
  const { authType, removeAuth, modules } = useContext(Context);

  const handlerLogout = (e) => {
    e.preventDefault();
    removeAuth();
  }

  return (
    <>
      <Sidebar 
        authType={authType}
        logout={handlerLogout}
        modules={modules}
      />
    </>
  )
}