import React from "react";
import { Formik } from "formik";
import { ButtonWrapper, SideTitle, Input, Button, ValidationMessage, Select, Icon, Checkbox } from "./styles";

import { createStoreSchema, editStoreSchema } from "./schema";


export const StoreForm = ({ data, onAdd, onUpdate, formToEdit, onClose, onBack }) => {


  const initialValues = {
    isMainStore: true,
    name: data.name || "",
    type: data.type || 0,
    isRestaurant: data.isRestaurant || false,
    isRetail: data.isRetail || false,
    invuToken: data.invuToken || "",
    shopifyPassword: data.shopifyPassword || "",
    wooCommerceUrl: data.wooCommerceUrl || "",
    wooCommerceKey: data.wooCommerceKey || "",
    wooCommerceSecret: data.wooCommerceSecret || "",
    pedidosYaId: data.pedidosYaId || "",
    invuTax: data.invuTax || "",
  }


  const handlerSubmit = (values) => {
    const body = {
      ...values,
      type: Number(values.type)
    };

    formToEdit ? onUpdate(data._id, body) : onAdd(body);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formToEdit ? editStoreSchema : createStoreSchema}
      onSubmit={(values, { setSubmitting }) => handlerSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {
        return (
          <>
            <SideTitle>{formToEdit ? "Modificar" : "Agregar"} Tienda </SideTitle>

            <form onSubmit={handleSubmit}>

              <Select
                label="Servicio"
                id="type"
                type="select"
                name="type"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.type)}
              >
                <option value={0} disabled> Seleccionar </option>
                <option value={1}> Shopify </option>
                <option value={2}> Magento </option>
                <option value={5}> Pedidos Ya </option>
                <option value={3}> WooComerce </option>
                <option value={4}> Wix </option>
              </Select>
              {errors.type && touched.type && <ValidationMessage>{errors.type}</ValidationMessage>}
              {Boolean(values.type) &&
                <>
                  <Checkbox
                    label="¿Es restaurante?"
                    id="is-restaruant"
                    name={`isRestaurant`}
                    onChange={handleChange}
                    checked={values.isRestaurant}
                    onBlur={handleBlur}
                    value={"isRestaurant"}
                  />
                  <Checkbox
                    label="¿Es Retail?"
                    id="is-retail"
                    name={`isRetail`}
                    onChange={handleChange}
                    checked={values.isRetail}
                    onBlur={handleBlur}
                    value={"isRetail"}
                  />
                </>
              }
              {Number(values.type) !== 0 &&
                <>

                  <Input
                    label="Nombre"
                    title={Number(values.type) === 1 ? "Nombre de la tienda en shopify" : "Nombre de la tienda"}
                    id="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name && <ValidationMessage>{errors.name}</ValidationMessage>}

                  <Input
                    label="Token de InvuPOS"
                    title="Token InvuPOS asignado a la tienda"
                    id="invuToken"
                    type="text"
                    name="invuToken"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.invuToken}
                  />
                  {errors.invuToken && touched.invuToken && <ValidationMessage>{errors.invuToken}</ValidationMessage>}

                  {Number(values.type) === 1 &&
                    <>
                      <Input
                        label="Constraseña"
                        title="Credencial generada en la configuracion de Shopify"
                        id="shopifyPassword"
                        type="text"
                        name="shopifyPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.shopifyPassword}
                      />
                      {errors.shopifyPassword && touched.shopifyPassword && <ValidationMessage>{errors.shopifyPassword}</ValidationMessage>}
                    </>
                  }

                  {Number(values.type) === 3 &&
                    <>

                      <Input
                        label="URL de la tienda"
                        title="URL de la tienda"
                        id="wooCommerceUrl"
                        type="text"
                        name="wooCommerceUrl"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.wooCommerceUrl}
                      />
                      {errors.wooCommerceUrl && touched.wooCommerceUrl && <ValidationMessage>{errors.wooCommerceUrl}</ValidationMessage>}

                      <Input
                        label="Llave de usuario"
                        title="Credencial Generada en la configuracion de WooCoomerce"
                        id="client-key"
                        type="text"
                        name="wooCommerceKey"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.wooCommerceKey}
                      />
                      {errors.wooCommerceKey && touched.wooCommerceKey && <ValidationMessage>{errors.wooCommerceKey}</ValidationMessage>}

                      <Input
                        label="Clave de usuario"
                        title="Credencial Generada en la configuracion de WooCoomerce"
                        id="client-secret"
                        type="text"
                        name="wooCommerceSecret"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.wooCommerceSecret}
                      />
                      {errors.wooCommerceSecret && touched.wooCommerceSecret && <ValidationMessage>{errors.wooCommerceSecret}</ValidationMessage>}
                    </>
                  }

                  {Number(values.type) === 5 &&
                    <>
                      <Input
                        label="ID de la tienda de Pedidos Ya"
                        title="ID de la tienda asignado por Pedidos Ya"
                        id="pedidosYaId"
                        type="text"
                        name="pedidosYaId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pedidosYaId}
                      />
                      {errors.pedidosYaId && touched.pedidosYaId && <ValidationMessage>{errors.pedidosYaId}</ValidationMessage>}
                      <Input
                        label="Impuesto INVU"
                        title="Impuesto de la tienda asignado por INVU"
                        id="invuTax"
                        type="text"
                        name="invuTax"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.invuTax}
                      />
                      {errors.invuTax && touched.invuTax && <ValidationMessage>{errors.invuTax}</ValidationMessage>}
                    </>
                  }
                </>
              }

              <ButtonWrapper>
                {Boolean(formToEdit) && <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>}
                {!Boolean(formToEdit) && <Button onClick={onClose} type="button"><Icon> close </Icon></Button>}
                <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
              </ButtonWrapper>
            </form>
          </>

        )
      }}

    </Formik>
  )
}