import { keyframes, css } from 'styled-components'

const fadeInKeyFrames = keyframes` 

   from {
    right: -50%;
  }

  to {
    right: 0px;
  }
 
`

export const fadeIn = ({ time = '.25s', type = 'ease-in' } = {}) => {
  return css`animation: ${time} ${fadeInKeyFrames} ${type};`
}

