import * as Yup from 'yup';

const requiredMessage = 'Este campo es requerido';

export const createStoreSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage),
  mainStore: Yup.string().required(),
  invuToken: Yup.string().required(requiredMessage)
})


export const editStoreSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage),
  invuToken: Yup.string().required(requiredMessage)
})