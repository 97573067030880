import styled, { css } from 'styled-components';
import {
  Button as MaterializeButton,
  TextInput as MaterializeInput,
  Icon as MaterializeIcon,
  Select as MaterializeSelect,
  Checkbox as MaterializeCheckbox,
  Switch as MaterializeSwitch,
  Pagination as MaterializePagination,
  ProgressBar as MaterializeProgressBar,
  Table as MaterializeTable
} from 'react-materialize';

import { fadeIn } from './animations';
import { primaryColor, secondaryColor } from '../config';


export const Button = styled(MaterializeButton)`
  /* background-color: ${primaryColor}; */
  margin: 1px;
  &:hover {
    /* background-color: ${secondaryColor}; */
  }
  &:active {
    /* background-color: ${secondaryColor}; */
  }
  &:focus {
    /* background-color: ${secondaryColor}; */
  }
`;

export const Input = styled(MaterializeInput)`
  &&& {
    &&:focus {
      padding: 0;

      /* border-bottom: 1px solid ${secondaryColor}; */
      /* box-shadow: 0 1px 0 0 ${secondaryColor}; */
    }
    &&:active {
      /* border-bottom: 1px solid ${secondaryColor}; */
      /* box-shadow: 0 1px 0 0 ${secondaryColor}; */
    }
    &&:focus + label {
      padding: 0;
      /* color: ${secondaryColor} !important; */
    }
  }
  
`;


export const Overlay = styled.div`
  display: none;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  ${props =>
    props.show && css`
      display: block !important;
      position: fixed;
    `
  }
`;

export const SidePanel = styled.div`
    position: absolute;
    width: 50%;
    right: -50%;
    top: 0;
    margin: 0;
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding: 10px 50px 70px 50px;
    background-color: #fff;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    ${props =>
    props.show && css`
      ${fadeIn()}
      right: 0px;
    `
  }
`

export const SideTitle = styled.h5`
  padding: 5px;
  margin-bottom: 30px;
`;


export const Icon = styled(MaterializeIcon)`
  ${props =>
    props.color &&
    css`
        color: ${props.color};
      `
  }

  ${props =>
    props.disable &&
    css`
        color: #e7e7e7;
      `
  }
`;

export const ValidationMessage = styled.div`
  margin-top: -25px;
  color: red;
  font-size: 14px;
  text-align: right;
`;

export const Select = styled(MaterializeSelect)``;


export const Checkbox = styled(MaterializeCheckbox)`
  &&&{
   
    &[type="checkbox"] + span:not(.lever)  {
      margin-right: 15px;
      padding-left: 20px;
    }
  }
`;

export const ConfigCheckbox = styled(MaterializeCheckbox)`
  &&&{
    &[type="checkbox"] + span:not(.lever)  {
      display: block;
      margin-right: 15px;
      padding-left: 20px;
    }
  }
`;

export const Switch = styled(MaterializeSwitch)`
 &&&{

    display:inline;
 
    & .switch .switch-label{
    display:inline;
  }
 }
  & .switch .switch-label{
    display:inline;
  }
  
`;


export const Table = styled(MaterializeTable)`
  width: 100%;
`;

export const Row = styled.tr`
  ${props =>
    props.disable && css`
      color: #b9a5a5;
    `
  }
`;

export const Cell = styled.td`
  padding: 5px 15px;
  ${props =>
    props.disable && css`
      color: #b9a5a5;
    `
  }
`;

export const Pagination = styled(MaterializePagination)`
  width: 40%;
  margin: auto;
  padding: 10px;
`

export const ProgressBar = styled(MaterializeProgressBar)`
  margin: 0;
`

export const ProgressBarWrapper = styled.div`
position: relative;
  
`;