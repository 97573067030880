import React, { useState, useEffect, useContext } from "react";
import { getStores, getStore, getClientStores, updateStore, addStore, disableStore, updateConfigStore, updatePaymentInfo, restartStore } from "../apis/stores";
import { syncProducts } from "../apis/products";
import { StoresList } from "../components/store/StoresList";
import { PaymentInfoForm } from "../components/store/PaymentInfoForm";
import { StoreForm } from "../components/store/StoreForm";
import { StoreInfo } from "../components/store/StoreInfo";
import { SubStoreForm } from "../components/store/SubStoreForm";
import { ConfigManual } from "../components/store/ConfigManual";
import { ConfigAuto } from "../components/store/ConfigAuto";
import { Floating } from "../components/Floating";
import { Title } from "../components/Title";
import { Modal } from "../components/Modal";
import { Pagination } from "../components/Pagination";
import { isEmpty } from "../utils/objects";
import { SidePanel as StorePanel, Overlay, ProgressBar } from "../styles/Theme";
import { Context } from "../Context";

export const StoresContainer = () => {

  const { token, authType, permissions, stores, setStores } = useContext(Context);
  const [loading, setLoading] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [formToEdit, setFormToEdit] = useState(false);
  const [mainStore, setMainStore] = useState({});
  const [forcedRequest, setForcedRequest] = useState(false);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [actionsModal, setModalActions] = useState([]);
  let component = false;
  const { stores: storesPermissions } = permissions;

  const updateStoresList = (stores, newStore, operation = undefined) => {

    let list = [];
    switch (operation) {
      case "add":
        list = stores
        if (newStore.isMainStore) list = [...stores, newStore];
        break;
      case "update":
        list = stores.map(store => {
          if (store._id === newStore._id) return newStore;
          return store;
        })
        break;
      default:
        list = stores
        break;
    }

    return list;
  }

  const handlerCreate = () => {
    setFormToEdit(false);
    setShow("form");
  }

  const handlerEdit = () => {
    setFormToEdit(true);
    setShow("form");
  }

  const handlerClose = () => {
    setShow(false);
    setFormToEdit(false);
    setData({});
  }

  const handlerSubBack = () => {
    if (!formToEdit) {
      setData(mainStore);
    }
    setShow("info");
  }

  const handlerBack = () => {
    setShow("info");
  }

  const handlerShowPanel = async (_id) => {
    setLoading(true);
    const { error = undefined, response: store = {} } = await getStore(_id, token);
    setLoading(false);
    if (store && !isEmpty(store)) {
      setData(store);
      setShow("info");
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener el datalle de la tienda");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }


  const handlerConfigManual = () => {
    setShow("configManual");
  }

  const handlerPagination = (value) => {
    setPage(value)
  }

  const handlerConfigAuto = () => {
    setShow("configAuto");
  }

  const handlerSubStoreForm = () => {
    setFormToEdit(false);
    setMainStore(data);
    setData({});
    setShow("subStoreForm");
  }

  const handlerSubStoreEditForm = () => {
    setMainStore({});
    setFormToEdit(true);
    setShow("subStoreForm");
  }

  const handlerConfigPaymentInfo = () => {
    setShow("configPaymentInfo");
  }

  const handlerCloseModal = () => {
    setModalMessage("");
    setModalHeader("Modal");
    setModalActions([]);
    setShowModal(false);
  }

  const handlerSyncProduct = async (_id) => {
    handlerClose();
    setLoading(true)
    const { error = undefined, response = "" } = await syncProducts(_id, token);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo sincronizar los productos de la tienda");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return false;
    }

    setModalHeader("Sincronizar productos");
    setModalMessage(response);
    const actions = [
      { description: "Cerrar", function: handlerCloseModal }
    ]
    setModalActions(actions);
    setShowModal(true);

  }

  const handlerDisable = async (_id) => {
    setLoading(true);
    const { error = undefined, response: store = {} } = await disableStore(_id, token);
    setLoading(false);

    if (store && !isEmpty(store)) {
      if (!store.isMainStore) setForcedRequest(!forcedRequest)
      const newStores = updateStoresList(stores, store, "update");
      setStores(newStores);
      setShow(false);
      setData({});
    }

    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo modificar el estado de la tienda");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerRestart = async (_id) => {
    setLoading(true);
    const { error = undefined, response } = await restartStore(_id, token);
    setLoading(false);
    if (response) {
      const { store, message } = response;
      if (store && !isEmpty(store)) {
        const newStores = updateStoresList(stores, store, "update");
        setStores(newStores);
        setShow(false);
        setData({});
      }

      setModalHeader("Restaurar tienda");
      setModalMessage(message);
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }

    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo restaurar los datos de la tienda");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }

  }


  const handlerAdd = async (formData) => {
    setLoading(true);
    const { error = undefined, response: store = {} } = await addStore(formData, token);
    setLoading(false);

    if (store && !isEmpty(store)) {
      if (!store.isMainStore) setForcedRequest(!forcedRequest)
      const storeList = updateStoresList(stores, store, "add");
      setStores(storeList);
      setShow(false);
      setData({});
    }

    if (error) {
      setModalHeader("Error creando tienda");
      setModalMessage(error);
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerUpdate = async (_id, formData) => {

    setLoading(true);
    const { error = undefined, response: store = {} } = await updateStore(_id, formData, token);
    setLoading(false);
    if (store && !isEmpty(store)) {
      if (!store.isMainStore) setForcedRequest(!forcedRequest)
      const updatedStores = updateStoresList(stores, store, "update");
      setStores(updatedStores);
      setShow(false);
      setData({});
    }
    if (error) {
      setModalHeader("Error modificando tienda");
      setModalMessage(error);
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerSaveManualSettings = async (_id, formData) => {
    const { config: { auto = {} } = {} } = data;
    const body = {
      manual: formData,
      auto
    }
    setLoading(true);
    const { error = undefined, response: store = {} } = await updateConfigStore(_id, body, token);
    setLoading(false);
    if (store && !isEmpty(store)) {
      const updatedStores = updateStoresList(stores, store, "update");
      setStores(updatedStores);
      setShow(false);
      setData({});
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo guardar la configuración manual");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerSaveAutoSettings = async (_id, formData) => {
    const { config: { manual = {} } = {} } = data;
    const body = {
      auto: { ...formData, frequency: Number(formData.frequency) || 1 },
      manual
    }
    setLoading(true);
    const { error = undefined, response: store = {} } = await updateConfigStore(_id, body, token);
    setLoading(false);
    if (store && !isEmpty(store)) {
      const updatedStores = updateStoresList(stores, store, "update");
      setStores(updatedStores);
      setShow(false);
      setData({});
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo guardar la configuración automatica");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerSavePaymentInfo = async (_id, formData) => {

    const body = {
      bankDeposit: Number(formData.bankDeposit),
      cash: Number(formData.cash),
      cashOnDelivery: Number(formData.cashOnDelivery),
      discountType: Number(formData.discountType),
      giftCard: Number(formData.giftCard),
      externalCredit: Number(formData.externalCredit),
      moneyOrder: Number(formData.moneyOrder),
      storeCredit: Number(formData.storeCredit),
      other: Number(formData.other),
      externalDebit: Number(formData.externalDebit)
    }

    setLoading(true);
    const { error = undefined, response: store = {} } = await updatePaymentInfo(_id, body, token);
    setLoading(false);
    if (store && !isEmpty(store)) {
      const updatedStores = updateStoresList(stores, store, "update");
      setStores(updatedStores);
      setShow(false);
      setData({});
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo guardar la configuración de pago");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const storeForm = (
    <StoreForm
      data={data}
      formToEdit={formToEdit}
      onUpdate={(_id, formData) => handlerUpdate(_id, formData)}
      onAdd={(formData) => handlerAdd(formData)}
      onClose={handlerClose}
      onBack={handlerBack}
    />
  );

  const subStoreForm = (
    <SubStoreForm
      data={data}
      formToEdit={formToEdit}
      onUpdate={(_id, formData) => handlerUpdate(_id, formData)}
      onAdd={(formData) => handlerAdd(formData)}
      mainStore={mainStore}
      onBack={handlerSubBack}
    />
  );

  const configManual = (
    <ConfigManual
      data={data}
      onSave={(_id, formData) => handlerSaveManualSettings(_id, formData)}
      onBack={handlerBack}
    />
  );

  const configAuto = (
    <ConfigAuto
      data={data}
      onSave={(_id, formData) => handlerSaveAutoSettings(_id, formData)}
      onBack={handlerBack}
    />
  );

  const paymentInfoForm = (
    <PaymentInfoForm
      data={data}
      onSave={(_id, formData) => handlerSavePaymentInfo(_id, formData)}
      onBack={handlerBack}
    />
  );

  const storeInfo = (
    <StoreInfo
      data={data}
      onEdit={handlerEdit}
      onRestart={(_id) => handlerRestart(_id)}
      onDisable={(_id) => handlerDisable(_id)}
      onClose={handlerClose}
      permissions={storesPermissions}
      authType={authType}
      onConfigManual={handlerConfigManual}
      onConfigAuto={handlerConfigAuto}
      syncProduct={(_id) => handlerSyncProduct(_id)}
      onAddSubStore={handlerSubStoreForm}
      onSubEdit={handlerSubStoreEditForm}
      onConfigPaymentInfo={handlerConfigPaymentInfo}
    />
  );

  const handlerShowCase = (showCase) => {

    switch (showCase) {
      case "info":
        component = storeInfo;
        break;
      case "form":
        component = storeForm
        break;
      case "configManual":
        component = configManual
        break;
      case "configAuto":
        component = configAuto
        break;
      case "subStoreForm":
        component = subStoreForm
        break;
      case "configPaymentInfo":
        component = paymentInfoForm
        break;
      default:
        setShow(false);
        break;
    }

    return component
  }

  const asyncGetStores = async () => {
    setLoading(true);
    const { error = undefined, response = [] } = await getStores(token, page);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener las tiendas");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return [];
    }
    return response;
  }

  const asyncGetClientStores = async () => {
    setLoading(true);
    const { error = undefined, response = [] } = await getClientStores(token, page);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener las tiendas");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return [];
    }
    return response;
  }

  useEffect(() => {
    const asyncRequest = async () => {
      let storeList = [];
      let pagination = {};
      if (authType === 3) {
        ({ stores: storeList, pagination = {} } = await asyncGetClientStores());
      } else {
        ({ stores: storeList, pagination = {} } = await asyncGetStores());
      }
      setStores(storeList);
      setPagination(pagination);
    }
    asyncRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);



  useEffect(() => {
    const asyncRequest = async () => {
      const { stores: storeList = [], pagination = {} } = await asyncGetStores();
      setStores(storeList);
      setPagination(pagination);
    }
    if (authType !== 3) {
      asyncRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRequest]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showModal]);

  return (
    <>
      {loading && <ProgressBar />}
      {showModal && <Modal show={showModal} header={headerModal} message={modalMessage} actions={actionsModal} />}

      {show &&
        <>
          <Overlay onClick={() => handlerClose()} show={show} />
          <StorePanel show={show} >
            {handlerShowCase(show)}
          </StorePanel>
        </>
      }

      <Title title="Tiendas" />
      <StoresList
        stores={stores}
        onReview={(_id) => handlerShowPanel(_id)}
      />
      {pagination.totalPages > 1 &&
        <Pagination
          pagination={pagination}
          onSetPage={(value) => handlerPagination(value)}
        />
      }
      {
        (authType && authType !== 3) &&
        (storesPermissions && storesPermissions.create) &&
        <Floating onAdd={() => handlerCreate()} />
      }
    </>
  )
}