module.exports = {
  // baseUrl: process.env.REACT_APP_BASE_URL || 'http://admin.invu2go.com/',
  // baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8443',
  // baseUrl: process.env.REACT_APP_BASE_URL || 'https://invu-admin-integrations.herokuapp.com',
  baseUrl: process.env.REACT_APP_BASE_URL || 'https://api.invupay.com:8443',
  backgroundImg: './img/background.jpg',
  logoImg: './logo.svg',
  adminLogoBackground: './adminLogoBackground.png',
  userLogoBackground: './userLogoBackground.png',
  primaryColor: '#2949a3',
  secondaryColor: '#416de8',
  tertiaryColor: '#e8e8e8',
}
