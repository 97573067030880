import React from 'react';
import { Pagination as ThemePagination, Icon } from '../../styles/Theme';

export const Pagination = ({ pagination, onSetPage }) => {
  
  const { totalPages } = pagination;

  return (
    <ThemePagination
      activePage={1}
      items={totalPages}
      leftBtn={<Icon>chevron_left</Icon>}
      maxButtons={8}
      rightBtn={<Icon>chevron_right</Icon>}
      onSelect={(value) => onSetPage(value)}
    />
  )
}