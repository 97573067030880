import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { getAvailablesStores } from '../../../apis/stores';
import { getStoresId } from '../../../utils/stores';

import { InfoWrapper, Divider, ButtonsWrapper, Item, ItemValue } from './styles';
import { Button, Icon, SideTitle, Select } from '../../../styles/Theme';
import { Context } from '../../../Context';

export const StoresAssignForm = ({ data, onBack, onAssign, onError }) => {

  const [availasbleStores, setAvailablesStores] = useState([]);

  const { token } = useContext(Context);

  const asyncGetStores = async () => {
    const { error = undefined, response = [] } = await getAvailablesStores(data._id, token);
    if (error) {
      onError();
      return [];
    }
    return response;
  }

  useEffect(() => {
    const asyncRequest = async () => {
      const storesList = await asyncGetStores();
      setAvailablesStores(storesList);
    }
    asyncRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storesID = getStoresId(data);
  const userStores = data.stores;

  return (
    <InfoWrapper>
      <SideTitle>Tiendas Asignadas</SideTitle>
      {userStores.length > 0 && userStores.map(store => (<Item key={store._id}> <ItemValue> {store.name} </ItemValue> </Item>))}
      {userStores.length === 0 && <Item key="0" > <ItemValue> No posee tiendas asignadas </ItemValue> </Item>}

      <Divider>ASIGNAR</Divider>
      <Formik
        initialValues={{ stores: storesID }}
        onSubmit={(values, { setSubmitting }) => onAssign(data._id, values)}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          dirty
        }) => {

          return (
            <>
              <form onSubmit={handleSubmit}>

                <Select
                  label="Tiendas"
                  id="stores"
                  type="select"
                  name="stores"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stores}
                  multiple={true}
                >
                  {availasbleStores.map(store => <option key={store._id} value={store._id} >{store.name}</option>)}
                </Select>


                <ButtonsWrapper>
                  <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>
                  <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
                </ButtonsWrapper>
              </form>
            </>

          )
        }}
      </Formik>
    </InfoWrapper>
  )
}