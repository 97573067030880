import styled from 'styled-components';


export const Panel = styled.div`
  background: #f3f3f3;
  min-height: 70vh;
  width: 90%;
  margin: 30px 0 0 5%;
  padding: 2em;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
`

export const PanelHeader = styled.div`
  font-weight: bold;
`;

export const PanelBody = styled.div`
  background: green;
  margin: 40px 0 0 0;
  min-height: 60vh;
  background: #fff;
  padding: 10px;
`
