import React from 'react';
import {
  Collapsible,
  CollapsibleItem,
  Collection,
  CollectionItem,
  Icon,
  ActionsWrapper,
  CollapsibleHeaderText,
  IconWrapper,
  TextWrapper,
  LabelText,
  SpanText
} from './styles';
import { Word } from '../../../utils/translate';

export const StoresList = ({ stores, onReview }) => {
  return (
    <>
      {stores && stores.length > 0 &&
        <Collapsible
          accordion
          popout
        >
          {stores.map(store => {
            return (
              <CollapsibleItem
                key={store._id}
                able={store.status.toString()}
                expanded={false}
                header={
                  <>
                    <CollapsibleHeaderText>
                      <LabelText>Nombre</LabelText> <SpanText>{store.name}</SpanText>
                      <LabelText> Tipo</LabelText> <SpanText>{Word[`storeType-${store.type}`]}</SpanText>
                      <LabelText> Usuario</LabelText>  <SpanText>{store.user ? store.user.name : 'Sin asignar'} </SpanText>
                    </CollapsibleHeaderText>
                    <ActionsWrapper>
                      <IconWrapper onClick={() => onReview(store._id)}><Icon>more_horiz</Icon></IconWrapper>
                    </ActionsWrapper>
                  </>
                }
              >
                <Collection>
                  {store.subStores.map(subStore => {
                    return (
                      <CollectionItem
                        key={subStore._id}
                        able={subStore.status.toString()}
                      >
                        <TextWrapper>
                          <LabelText>Nombre</LabelText>  <SpanText>{subStore.name}</SpanText>
                        </TextWrapper>
                        <ActionsWrapper>
                          <IconWrapper onClick={() => onReview(subStore._id)}><Icon>more_horiz</Icon></IconWrapper>
                        </ActionsWrapper>
                      </CollectionItem>
                    )
                  })}
                </Collection>

              </CollapsibleItem>
            )
          })
          }
        </Collapsible>
      }
    </>
  )
}