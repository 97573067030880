import React from 'react';
import { InfoWrapper, Item, ItemLabel, ItemValue, ButtonsWrapper } from './styles';
import { SideTitle, Button, Icon } from '../../../styles/Theme';
import { Word } from '../../../utils/translate';

export const StoreInfo = ({
  data,
  onEdit,
  onDisable,
  onClose,
  permissions,
  authType,
  onConfigManual,
  onConfigAuto,
  onConfigPaymentInfo,
  syncProduct,
  onAddSubStore,
  onSubEdit,
  onRestart
}) => {

  return (
    <InfoWrapper>
      <SideTitle>Datos de la {!data.isMainStore ? 'Sub' : ''} tienda</SideTitle>
      <Item>
        <ItemLabel>TIPO: </ItemLabel> <ItemValue> {Word[`storeType-${data.type}`]} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>NOMBRE: </ItemLabel> <ItemValue> {data.name} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>INVU TOKEN: </ItemLabel> <ItemValue> {data.invuToken} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>ESTADO: </ItemLabel> <ItemValue> {data.status ? 'Habilitada' : 'Deshabilitada'} </ItemValue>
      </Item>
      {data.isMainStore &&
        <>
          {data.type === 1 &&
            <Item>
              <ItemLabel>Contraseña de Shopify: </ItemLabel> <ItemValue> {data.shopifyPassword} </ItemValue>
            </Item>
          }
          {data.type === 3 &&
            <>
              <Item>
                <ItemLabel>Url de WooCommerce: </ItemLabel> <ItemValue> {data.wooCommerceUrl} </ItemValue>
              </Item>
              <Item>
                <ItemLabel>Llave de WooCommerce: </ItemLabel> <ItemValue> {data.wooCommerceKey} </ItemValue>
              </Item>
              <Item>
                <ItemLabel>Contraseña de WooCommerce: </ItemLabel> <ItemValue> {data.wooCommerceSecret} </ItemValue>
              </Item>
            </>
          }
        </>
      }
      {authType && authType === 3 &&
        <ButtonsWrapper>
          {data.isMainStore &&
            <>
              <Button onClick={() => onConfigManual()}>Manual <Icon right> settings </Icon></Button>
              <Button onClick={() => onConfigAuto()}>Automatica<Icon right> settings </Icon></Button>
              <Button onClick={() => onConfigPaymentInfo()}>Pagos<Icon right> settings </Icon></Button>
              <Button onClick={() => syncProduct(data._id)}>Sincronizar<Icon right> autorenew </Icon></Button>
            </>
          }
          <Button onClick={() => onClose()}><Icon> close </Icon></Button>
        </ButtonsWrapper>
      }
      {authType && authType !== 3 &&
        <ButtonsWrapper>
          {permissions.update && data.isMainStore && <Button onClick={() => onRestart(data._id)}>Restaurar <Icon right> loop </Icon></Button>}
          {permissions.update && <Button onClick={() => data.isMainStore ? onEdit() : onSubEdit()}>Editar <Icon right> edit </Icon></Button>}
          {permissions.update && data.isMainStore && <Button onClick={() => onAddSubStore()}>Subtienda <Icon right> add </Icon></Button>}
          {permissions.disable &&
            <Button onClick={() => onDisable(data._id)} >
              {data.status ? 'Deshabilitar' : 'Habilitar'}
              {data.status ? <Icon right> not_interested </Icon> : <Icon right> check </Icon>}
            </Button>
          }
          <Button onClick={() => onClose()} type="button"><Icon> close </Icon></Button>
        </ButtonsWrapper>
      }

    </InfoWrapper>
  )
}