import styled from 'styled-components';
import { 
  Select as ThemeSelect,
  Checkbox as ThemeCheckbox,
  Input as ThemeInput
} from '../../../styles/Theme';
export const FilterWrapper = styled.div`
  padding: 10px 40px;
`;

export const ButtonsWrapper = styled.div`
  display: inline;
`;

export const Select = styled(ThemeSelect)``

export const Checkbox = styled(ThemeCheckbox)``

export const Input = styled(ThemeInput)`
  &&& {
    width: 50px;
  }
`