import React from 'react';
import { Formik } from 'formik';
import { ButtonWrapper, SideTitle, Input, Button, ValidationMessage, Icon } from './styles';

import { createStoreSchema, editStoreSchema } from './schema';


export const SubStoreForm = ({ data, onAdd, onUpdate, formToEdit, onBack, mainStore }) => {

  const initialValues = {
    isMainStore: false,
    mainStore: formToEdit ? data.mainStore.name : mainStore.name,
    type: formToEdit ? data.mainStore.type : mainStore.type,
    name: data.name || '',
    invuToken: data.invuToken || "",
    shopifyPassword: data.shopifyPassword || "",
    wooCommerceUrl: data.wooCommerceUrl || "",
    wooCommerceKey: data.wooCommerceKey || "",
    wooCommerceSecret: data.wooCommerceSecret || ""

  }

  const handlerSubmit = (values) => {
    const body = {
      ...values,
      mainStore: mainStore._id,
      type: Number(values.type)
    };

    formToEdit ? onUpdate(data._id, body) : onAdd(body);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formToEdit ? editStoreSchema : createStoreSchema}
      onSubmit={(values, { setSubmitting }) => handlerSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {

        return (
          <>
            <SideTitle>{formToEdit ? 'Modificar' : 'Agregar'} Sub Tienda </SideTitle>

            <form onSubmit={handleSubmit}>

              <Input
                label="Tienda principal"
                id="name"
                type="text"
                readOnly={true}
                name="mainStore"
                value={values.mainStore}
              />

              <Input
                label="Nombre"
                id="name"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && <ValidationMessage>{errors.name}</ValidationMessage>}

              <Input
                label="Token de InvuPOS"
                id="invuToken"
                type="text"
                name="invuToken"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invuToken}
              />
              {errors.invuToken && touched.invuToken && <ValidationMessage>{errors.invuToken}</ValidationMessage>}

              <ButtonWrapper>
                <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>
                <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
              </ButtonWrapper>
            </form>
          </>

        )
      }}

    </Formik>
  )
}