import React, { useState, useEffect, useContext } from "react";
import { getModules, getUsers, getUser, updateUser, addUser, disableUser, assignStores } from "../apis/users";
import { UsersList } from "../components/user/UsersList";
import { UserForm } from "../components/user/UserForm";
import { UserInfo } from "../components/user/UserInfo";
import { StoresAssignForm } from "../components/user/StoresAssignForm";
import { Modal } from "../components/Modal";
import { Floating } from "../components/Floating";
import { Title } from "../components/Title";
import { isEmpty } from "../utils/objects";
import { SidePanel as UserPanel, Overlay, ProgressBar } from "../styles/Theme";
import { buildAccess } from "../utils/access";
import { Context } from "../Context";
export const UsersContainer = () => {

  const { token, permissions, authType } = useContext(Context);
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState("");
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [formToEdit, setFormToEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [actionsModal, setModalActions] = useState([]);

  const { users: usersPermissions } = permissions;

  const updateUsersList = (users, newUser, operation = undefined) => {
    let list = [];

    switch (operation) {
      case "add":
        list = [...users, newUser];
        break;
      case "update":
        list = users.map(user => {
          if (user._id === newUser._id) return newUser;
          return user;
        })
        break;
      default:
        list = users
        break;
    }

    return list;
  }

  const handlerCreate = () => {
    setFormToEdit(false);
    setShow("form");
  }

  const handlerEdit = () => {
    setLoading(true);
    setFormToEdit(true);
    setShow("form");
    setLoading(false);
  }


  const handlerClose = () => {
    setFormToEdit(false);
    setShow(false);
    setData({});
  }

  const handlerBack = () => {
    setShow("info");
  }

  const handlerAssingError = () => {
    setModalHeader("Algo salió mal");
    setModalMessage("No se pudo obtener las tiendas disponibles");
    const actions = [
      { description: "Cerrar", function: handlerCloseModal }
    ]
    setModalActions(actions);
    setShowModal(true);
    return false;
  }

  const handlerCloseModal = () => {
    setModalMessage("");
    setModalHeader("Modal");
    setModalActions([]);
    setShowModal(false);
  }

  const handlerShowPanel = async (_id) => {
    setLoading(true);
    const { error = undefined, response = {} } = await getUser(_id, token);
    setLoading(false);

    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage("No se pudo obtener el datalle del usuario");
      const actions = [
        { description: "Cerrar", function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return false;
    }

    setData(response);
    setShow("info")
  }

  const handlerShowAssign = () => {
    setLoading(true);
    setShow("assign");
    setLoading(false);
  }

  const handlerDisable = async (_id) => {
    setLoading(true);
    const { error = undefined, response: user = {} } = await disableUser(_id, token);
    setLoading(false);
    if (user && !isEmpty(user)) {
      const NewUsers = updateUsersList(users, user, "update");
      setUsers(NewUsers);
      setShow(false);
      setData({})
    }
    if (error) {
      setShow("info");
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo modificar el estado del usuario');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerAssaign = async (_id, stores) => {
    setLoading(true);
    const { error = undefined, response } = await assignStores(_id, stores, token);
    setLoading(false);
    if (!error) {
      setData(response);
      setShow("info");
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo asignar la tienda al usuario');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerAdd = async (formData) => {
    setLoading(true);
    const access = buildAccess(formData);

    const {
      email,
      name,
      password
    } = formData;

    const newUser = {
      email,
      name,
      password,
      access
    }
    const { error = undefined, response: user = {} } = await addUser(newUser, token);

    if (user && !isEmpty(user)) {
      const userList = updateUsersList(users, user, "add");
      setShow(false);
      setUsers(userList);
      setData({});
    }

    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo crear el nuevo usuario');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const handlerUpdate = async (_id, formData) => {

    const access = buildAccess(formData);
    const {
      email,
      name,
      password
    } = formData;

    const newUser = {
      email,
      name,
      password,
      access
    }
    setLoading(true);
    const { error = undefined, response: user = {} } = await updateUser(_id, newUser, token);
    setLoading(false);
    if (user && !isEmpty(user)) {
      const updatedUsers = updateUsersList(users, user, "update");
      setData({});
      setUsers(updatedUsers);
      setShow(false);
    }
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo modificar el usuario');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
    }
  }

  const asyncGetUsers = async () => {
    setLoading(true);
    const { error = undefined, response = [] } = await getUsers(token);
    setLoading(false);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo obtener la lista de usuarios');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return [];
    }
    return response;
  }

  const asyncGetModules = async () => {
    const { error = undefined, response = [] } = await getModules(token);
    if (error) {
      setModalHeader("Algo salió mal");
      setModalMessage('No se pudo obtener la lista de modulos');
      const actions = [
        { description: 'Cerrar', function: handlerCloseModal }
      ]
      setModalActions(actions);
      setShowModal(true);
      return [];
    }
    return response;
  }

  const userForm = (<UserForm
    data={data}
    modules={modules}
    formToEdit={formToEdit}
    onUpdate={(_id, formData) => handlerUpdate(_id, formData)}
    onAdd={(formData) => handlerAdd(formData)}
    authType={authType}
    onClose={handlerClose}
    onBack={handlerBack}
  />);

  const storeAssing = (<StoresAssignForm
    data={data}
    onAssign={(_id, formData) => handlerAssaign(_id, formData)}
    onBack={handlerBack}
    onError={handlerAssingError}
  />);

  const userInfo = (
    <UserInfo
      data={data}
      onEdit={handlerEdit}
      onDisable={(_id) => handlerDisable(_id)}
      onAssign={(_id) => handlerShowAssign(_id)}
      onClose={handlerClose}
      permissions={usersPermissions}
    />
  );

  const handlerShowCase = (showCase) => {
    let component = undefined;
    switch (showCase) {
      case "info":
        component = userInfo;
        break;
      case "assign":
        component = storeAssing
        break;
      case "form":
        component = userForm
        break;

      default:
        component = <></>
        break;
    }

    return component
  }

  useEffect(() => {
    const asyncRequest = async () => {

      const usersList = await asyncGetUsers();
      setUsers(usersList);

      const modulesList = await asyncGetModules();
      setModules(modulesList);

    }
    asyncRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showModal]);

  return (
    <>
      {loading && <ProgressBar />}
      {showModal && <Modal show={showModal} header={headerModal} message={modalMessage} actions={actionsModal} />}

      {show &&
        <>
          <Overlay onClick={() => handlerClose()} show={show} />
          <UserPanel show={show} >
            {handlerShowCase(show, formToEdit)}
          </UserPanel>
        </>
      }

      <Title title="Usuarios" />
      <UsersList
        users={users}
        onReview={(_id) => handlerShowPanel(_id)}
      />
      {usersPermissions && usersPermissions.create && authType !== 3 && <Floating onAdd={() => handlerCreate()} />}
    </>
  )
}