import React from 'react';
import { logoImg } from '../../config';
import { IconText } from '../../utils/translate';
import {
  SidebarWrapper,
  SideLink,
  Icon,
  SideButton,
  DataUserWrapper,
  HeaderLogo,
  HeaderLogoWrapper,
} from './styles';

export const Sidebar = ({ authType, logout, modules }) => {

  return (
    <SidebarWrapper fixed >
      <DataUserWrapper>
        <SideLink to='/'>
          <HeaderLogoWrapper authType={authType}>
            <HeaderLogo src={logoImg} />
          </HeaderLogoWrapper>
        </SideLink>
      </DataUserWrapper>
      {modules.length > 0 &&
        modules.map(module => (
          <SideLink key={module.key} to={`/${module.key}`} >
            <SideButton waves="light">
              <Icon left>{IconText[module.key]}</Icon>
              {module.name}
            </SideButton>
          </SideLink>
        ))
      }
      <SideLink to="/" onClick={(e) => logout(e)}>
        <SideButton waves="light">
          <Icon left> lock </Icon>
          Logout
        </SideButton>
      </SideLink>


    </SidebarWrapper>

  )
}