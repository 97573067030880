import styled from 'styled-components';


export const InfoWrapper = styled.div`
  
`;

export const Item = styled.div`
  padding: 5px ;
  margin-bottom: 5px;
`;

export const ItemLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ItemValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #696969;
  text-transform: uppercase;
`;


export const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;