import React, { useContext } from 'react';
import { Context } from '../Context';
import { HomePanel } from '../components/HomePanel';

export const HomeContainer = () => {
  const { authType, name } = useContext(Context);
  return (
    <HomePanel 
      authType={authType}
      name={name}
    />
  );

}