import React from 'react';
import { Formik } from 'formik';

import { ButtonWrapper, SideTitle, Button, Icon, ValidationMessage, Select } from './styles';


export const PaymentInfoForm = ({ data, onSave, onBack }) => {

  const {
    paymentConfig = {},
    discountType = undefined,
    paymentMethods = [],
    discountTypes = []
  } = data;

  const initialValues = {
    bankDeposit: paymentConfig.bankDeposit || 0,
    cash: paymentConfig.cash || 0,
    cashOnDelivery: paymentConfig.cashOnDelivery || 0,
    externalCredit: paymentConfig.externalCredit || 0,
    externalDebit: paymentConfig.externalDebit || 0,
    giftCard: paymentConfig.giftCard || 0,
    moneyOrder: paymentConfig.moneyOrder || 0,
    storeCredit: paymentConfig.storeCredit || 0,
    other: paymentConfig.other || 0,
    discountType: discountType || 0,
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={formToEdit ? editStoreSchema : createStoreSchema}
      onSubmit={(values, { setSubmitting }) => onSave(data._id, values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {

        return (
          <>
            <SideTitle>Descuento y metodos de pago </SideTitle>

            <Select
                label="Descuento aceptado"
                id="discountType"
                type="select"
                name="discountType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.discountType)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {discountTypes.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.descripcion} </option>
                  )
                })}
              </Select>
              {errors.discountType && touched.discountType && <ValidationMessage>{errors.discountType}</ValidationMessage>}

            <form onSubmit={handleSubmit}>
              <Select
                label="Deposito bancario"
                id="back-deposit"
                type="select"
                name="bankDeposit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.bankDeposit)}
              >
                <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.bankDeposit && touched.bankDeposit && <ValidationMessage>{errors.bankDeposit}</ValidationMessage>}

              <Select
                label="Efectivo"
                id="cash"
                type="select"
                name="cash"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.cash)}
              >
                <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.cash && touched.cash && <ValidationMessage>{errors.cash}</ValidationMessage>}

              <Select
                label="Efectivo en entrega"
                id="cash-on-delivery"
                type="select"
                name="cashOnDelivery"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.cashOnDelivery)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.cashOnDelivery && touched.cashOnDelivery && <ValidationMessage>{errors.cashOnDelivery}</ValidationMessage>}

              <Select
                label="Credito"
                id="externalCredit"
                type="select"
                name="externalCredit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.externalCredit)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.externalCredit && touched.externalCredit && <ValidationMessage>{errors.externalCredit}</ValidationMessage>}

              <Select
                label="Debito"
                id="externalDebit"
                type="select"
                name="externalDebit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.externalDebit)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.externalDebit && touched.externalDebit && <ValidationMessage>{errors.externalDebit}</ValidationMessage>}

              <Select
                label="Gift Card"
                id="giftCard"
                type="select"
                name="giftCard"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.giftCard)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.giftCard && touched.giftCard && <ValidationMessage>{errors.giftCard}</ValidationMessage>}

              <Select
                label="Giro postal"
                id="moneyOrder"
                type="select"
                name="moneyOrder"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.moneyOrder)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.moneyOrder && touched.moneyOrder && <ValidationMessage>{errors.moneyOrder}</ValidationMessage>}

              <Select
                label="Credito de la tienda"
                id="storeCredit"
                type="select"
                name="storeCredit"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.storeCredit)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.storeCredit && touched.storeCredit && <ValidationMessage>{errors.storeCredit}</ValidationMessage>}

              <Select
                label="Otro"
                id="other"
                type="select"
                name="other"
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.other)}
              >
                 <option key={0} value={0} > Seleccionar </option>
                {paymentMethods.map(method => {                  
                  return (
                    <option key={method.id} value={method.id} > {method.nombre} </option>
                  )
                })}
              </Select>
              {errors.other && touched.other && <ValidationMessage>{errors.other}</ValidationMessage>}

              <ButtonWrapper>
                <Button onClick={onBack} type="button"><Icon> keyboard_arrow_left </Icon></Button>
                <Button type="submit" disabled={!isValid || !dirty}> Guardar </Button>
              </ButtonWrapper>
            </form>
          </>

        )
      }}

    </Formik>
  )
}