import React, { useState, useEffect, useContext } from 'react';
import { LoginForm } from '../components/LoginForm';
import { login } from '../apis/auth';
import { useImput } from '../hooks/useInput';
import { Context } from '../Context';

export const LoginContainer = () => {

  // Context
  const { activateAuth, removeAuth } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const email = useImput("");
  const password = useImput("");

  // Hook: maneja el timer de la alerta de error.
  useEffect(() => {
    const timer = setTimeout(() => {
      setError(null);
    }, 2000);
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    removeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSubmit = async (formData) => {
    
    setLoading(true);
    const { error = undefined, response: { token = '' } = '' } = await login(formData);
    setLoading(false);
    if (token) {      
      activateAuth(token)
    }
    if (error) {
      setError(error);
    }
  };

  return (
    <>
      <LoginForm
        onSubmit={(formData) => handlerSubmit(formData)}
        email={email}
        password={password}
        disabled={Boolean(error) || loading}
        error={error}
      />
    </>
  )
}