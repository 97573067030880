import React from 'react';
import { FloatingButton, Icon } from './styles';
export const Floating = ({ onAdd }) => {
  return (

    <FloatingButton
      floating
      icon={<Icon>add</Icon>}
      onClick={() => onAdd()}
      node="button"
      waves="light"
    />
  )
}