import styled from 'styled-components';

export const OptionWrapper = styled.div`
  padding: 10px;
  
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
`;
