export const Word = {
  user: 'Usuario',
  store: 'Tienda',
  product: 'Producto',
  users: 'Usuarios',
  stores: 'Tiendas',
  products: 'Productos',
  "user-1": 'Administrador',
  "user-2": 'Soporte',
  "user-3": 'Cliente',
  "status-0": 'Deshabilitado',
  "status-1": 'Habilitado',
  "storeType-1": 'Shopify',
  "storeType-2": 'Magento',
  "storeType-3": 'WooComerce',
  "storeType-4": 'Wix',
  "storeType-5": 'Pedidos Ya',
}

export const IconText = {
  users: 'person', 
  stores: 'store', 
  products: 'shopping_cart', 
}