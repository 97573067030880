import React from 'react';
import { Panel, PanelHeader, PanelBody } from './styles';

export const HomePanel = ({ name, authType }) => {

  return (
    <Panel>
      <PanelHeader>
       <h4>Bienvenido {name}</h4>

      </PanelHeader>

      <PanelBody>
        
      </PanelBody>
    </Panel>
  )
}