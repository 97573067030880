import { baseUrl } from "../config";
import { api } from "../utils/api";


// Function: ejecuta el fetch para traer la lista de productos de InvuPOS GET: /products
export const syncProducts = async (id, token) => {  
  
  const endpoint = `${baseUrl}/products/sync/${id}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};


// Function: ejecuta el fetch para traer la lista de productos GET: /products/availables/:id
export const getAvailablesProducts = async (id, token, page, filters = {}) => {  
  
  const endpoint = `${baseUrl}/products/availables/${id}?page=${page}`;
  let params = '';
  if(filters.errors) params = `${params}&errors=1`
  if(filters.success) params = `${params}&success=1`
  
 const url = endpoint+params;
  
  const response = await api(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

// Function: ejecuta el fetch para traer 1 producto GET: /products/:id
export const getProduct = async (id, token) => {  
  
  const endpoint = `${baseUrl}/products/${id}`;
  
  const response = await api(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};


// Function: ejecuta el fetch para modificar 1 producto PUT: /products/:id
export const updateProduct = async (id, body, token) => {  
  
  const endpoint = `${baseUrl}/products/${id}`;
  
  const response = await api(endpoint, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
  
  return response;
};

