import React from 'react';
import { Formik } from 'formik';
import { LoginWrapper, LogoContainer, FormPanel, Input, Button } from './styles';
import { ValidationMessage } from '../../styles/Theme';
import { logoImg } from '../../config';
import { loginSchema } from './schema';

export const LoginForm = ({ onSubmit, email, password, disabled, error }) => {

  const initialValues = {
    email: '',
    password: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        isValid,
        dirty
      }) => {

        return (
          <>
            <LoginWrapper>
              <LogoContainer> <img src={logoImg} alt="logo" /> </LogoContainer>
              
              <form onSubmit={handleSubmit}>
                <FormPanel>
                  <Input
                    label="Correo"
                    id="email"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && <ValidationMessage>{errors.email}</ValidationMessage>}
                  <Input
                    label="Contraseña"
                    id="password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && <ValidationMessage>{errors.password}</ValidationMessage>}
                  {error && <ValidationMessage>{error}</ValidationMessage>}
                  <Button type="submit" disabled={!isValid || !dirty}> Ingresar </Button>
                  <h6>V1.1.4</h6>
                </FormPanel>
              </form>
            </LoginWrapper>

          </>

        )
      }}

    </Formik>
  )
  
}
