import { getErrorMessage } from './handlerError';

// Helper: función genérica para fetch 
export const api = async (url, options) => {
  try {
    let response = undefined;
    let error = undefined;

    const res = await fetch(url, options);
    const json = await res.json();

    if (json.code === 200 && json.data) {
      response = json.data;
    } else {
      error = getErrorMessage(json.code || 500);
    }

    return { response, error};

  } catch (err) {
    console.log(err);
    return { response: undefined, error: "Error no controlado", code: 500 };
  }
};
