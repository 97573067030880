import styled from 'styled-components';

export const InfoWrapper = styled.div``;

export const Item = styled.div`
  padding: 5px ;
  margin-bottom: 5px;
`;

export const ItemLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ItemValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #696969;
  text-transform: uppercase;
`;

export const Divider = styled.span`
  display: block; 
  border-bottom: 2px #f0f0f0 solid;
  font-weight: bold;
  font-size: 16px;
  margin: 20px 0px 5px 0px;
  color: #9e9e9e;
`


export const PermissionsLabel = styled.label`
  font-size: 16px;
  display: block;
  font-weight: bold;
  padding: 2px;
  line-height: 1em;
  text-transform: uppercase;
`;

export const PermissionsIcons = styled.div`
  display: inline;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;


