import React from 'react';
import { getPermissionsByModule } from '../../../utils/access';
import { Word } from '../../../utils/translate';
import { Checkbox } from '../../../styles/Theme';
import { PermissionTitle } from './styles';

export const PermissionCheckboxs = ({ data, module, handleChange, handleBlur }) => {
  const permissions = getPermissionsByModule(data, module);

  return (
    <>
      <PermissionTitle> {Word[module]} </PermissionTitle>
      <Checkbox
        label="Crear"
        id={`${module}-create`}
        name={`permissions.${module}`}
        onChange={handleChange}
        checked={Boolean(permissions.create)}
        onBlur={handleBlur}
        value={"create"}
      />
      <Checkbox
        label="Revisar"
        id={`${module}-review`}
        name={`permissions.${module}`}
        onChange={handleChange}
        checked={Boolean(permissions.review)}
        onBlur={handleBlur}
        value={"review"}
      />
      <Checkbox
        label="Modificar"
        id={`${module}-update`}
        name={`permissions.${module}`}
        onChange={handleChange}
        checked={Boolean(permissions.update)}
        onBlur={handleBlur}
        value={"update"}
      />
      <Checkbox
        label="Deshabilitar"
        id={`${module}-disable`}
        name={`permissions.${module}`}
        onChange={handleChange}
        checked={Boolean(permissions.disable)}
        onBlur={handleBlur}
        value={"disable"}
      />
    </>
  )
}