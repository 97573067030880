import React, { useState, useContext, useEffect } from 'react';
import 'materialize-css/dist/js/materialize';

import { GlobalStyles } from './styles/GlobalStyles'
import { Router, Redirect } from '@reach/router';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Users } from './pages/Users';
import { Products } from './pages/Products';
import { Stores } from './pages/Stores';
import { SidebarContainer } from './containers/SidebarContainer';
import { ProgressBar } from './styles/Theme';

import { Context } from './Context';

export const App = () => {
  const { token, handlerProfile, authType } = useContext(Context);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const asyncHandler = async () => {
      await handlerProfile(token)
    }
    if (token) {
      setLoading(true);
      asyncHandler(token);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <GlobalStyles />
      {loading && <ProgressBar />}
      {token && !loading && <SidebarContainer />}

      {!loading && !token &&
        <Router>
          <Login path="/login" />
          <Redirect noThrow from="*" to="/login" />
        </Router>
      }

      {token && authType !== 0 &&
        <Router>
          <Redirect noThrow from="/login" to="/" />
          <Users path="/users" />
          <Stores path="/stores" />
          <Products path="/products" />
          <Home path="/" />
        </Router>
      }

    </>
  )
}
