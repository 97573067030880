import React from 'react';
import { InfoWrapper, Item, ItemLabel, ItemValue, PermissionsLabel, PermissionsIcons, Divider, ButtonsWrapper } from './styles';
import { SideTitle, Button, Icon, Checkbox } from '../../../styles/Theme';
import { getAccess } from '../../../utils/access';
import { Word } from '../../../utils/translate';

export const UserInfo = ({ data, onEdit, onDisable, onClose, onAssign, permissions }) => {
  const {
    name,
    email,
    access
  } = data;

  return (
    <InfoWrapper>
      <SideTitle>Datos de usuario</SideTitle>
      <Item>
        <ItemLabel>NOMBRE: </ItemLabel> <ItemValue> {name} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>CORREO: </ItemLabel> <ItemValue> {email} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>TIPO: </ItemLabel> <ItemValue> {Word[`user-${access.type}`]} </ItemValue>
      </Item>
      <Item>
        <ItemLabel>ESTADO: </ItemLabel> <ItemValue> {Word[`status-${Number(access.status)}`]} </ItemValue>
      </Item>
      <Divider>PERMISOS</Divider>
      {data.access.modules.map((item, key) => (
        <Item key={key} >
          <PermissionsLabel>{item.name}</PermissionsLabel>
          <PermissionsIcons>
            <Checkbox label="Crear" value="1" disabled checked={getAccess(data, item.key, "create")} />
            <Checkbox label="Revisar" value="1" disabled checked={getAccess(data, item.key, "review")} />
            <Checkbox label="Modificar" value="1" disabled checked={getAccess(data, item.key, "update")} />
            <Checkbox label="Deshabilitar" value="1" disabled checked={getAccess(data, item.key, "disable")} />
          </PermissionsIcons>
        </Item>
      ))
      }
      <ButtonsWrapper>
        {permissions.update && <Button onClick={() => onEdit(data._id)}>  Editar  <Icon right> edit </Icon></Button>}
        {permissions.update && <Button onClick={() => onAssign(data._id)} >  Asignar tienda <Icon right> store </Icon></Button>}
        {permissions.disable && 
          <Button onClick={() => onDisable(data._id)} >
            {access.status ? 'Deshabilitar' : 'Habilitar'}
            {access.status ? <Icon right> not_interested </Icon> : <Icon right> check </Icon>}
          </Button>
        }
        <Button onClick={() => onClose()} type="button"><Icon> close </Icon></Button>
      </ButtonsWrapper>
    </InfoWrapper>
  )
}