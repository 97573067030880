import querystring from "querystring";
import { baseUrl } from "../config";
import { api } from "../utils/api";

// Function: ejecuta el fetch para hacer la autenticación POST: /auth/user
export const login = async (body) => {  
  const endpoint = `${baseUrl}/auth/user`;

  const response = await api(endpoint, {
    method: "POST",
    body: querystring.stringify(body),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
  
  return response;
};
